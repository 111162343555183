import React, { useRef, useState, useEffect } from 'react';
import './index_p.scss'
import backimg_h from '../../images/background_image_h.png'
import backimg_l from '../../images/background_image_l.png'
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
const Features = React.lazy(() => import('../features/Features'));
const Quote = React.lazy(() => import('../quote/Quote'));
const Faq = React.lazy(() => import('../faq/Faq'));
function Index_P() {
  const { t, i18n } = useTranslation();
  const { scrollToFooter } = useOutletContext();
  const [backgroundImage, setBackgroundImage] = useState(backimg_l);

  useEffect(() => {
    const debounce = (func, delay) => {
      let timer;
      return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => func(...args), delay);
      };
    };
  
    const updateBackground = debounce(() => {
      if (window.innerWidth >= 1101) {
        setBackgroundImage(backimg_h);
      } else {
        setBackgroundImage(backimg_l);
      }
    }, 100); // 200ms затримка
  
    updateBackground();
    window.addEventListener('resize', updateBackground);
  
    return () => window.removeEventListener('resize', updateBackground);
  }, []);
  return (
    
    <div id='index_p'>

      
      <div className='plane' style={{background: `linear-gradient(to bottom, rgba(255, 255, 255, 0) 90%, rgba(255, 255, 255, 1) 100%), url(${backgroundImage})`}}>
      <div className='learn-more'>

          
          <h1>{t('titleH1')}</h1>
          <h1>{t('titleH2')}</h1>
          <h5>{t('titlep1')}</h5>
          <p>{t('titlep2')}</p>
          <div>
            <button onClick={scrollToFooter}>{t('contactExpert')}<span style={{fontSize:'25px'}}> →</span></button>
          </div>


      </div>

      <Features/>
      </div>
      <Quote/>
      
        <Faq/>
      
    </div>
  )
}

export default Index_P
